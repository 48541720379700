// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RBDBz{position:relative;overflow:hidden;background:#181716;border-radius:8px;-webkit-mask-image:-webkit-radial-gradient(#fff,#000)}.RBDBz:after,.RBDBz:before{position:absolute;z-index:-1;bottom:0;height:204px;background-size:102px 204px;content:\"\"}.RBDBz:before{left:0;width:204px;background-image:radial-gradient(circle 204px at left,#232323 50%,transparent 0)}.RBDBz:after{left:408px;width:102px;background-image:radial-gradient(circle 204px at right,#232323 50%,transparent 0)}.rOXPB{position:absolute;bottom:0;left:50%;width:204px;height:204px;margin-left:-104px;background:#232323;border-radius:100%;content:\"\";transition:transform .3s ease-out,background-color .3s ease-out}.RBDBz:active .rOXPB{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#101010;transform:scale(3.61)}@media(any-hover:hover){.RBDBz:hover .rOXPB{background-color:#101010;transform:scale(3.61)}}._7EdYX{position:relative;z-index:1;flex-direction:column}._7EdYX,.ulGT3{display:flex;width:100%;height:100%;align-items:center}.ulGT3{flex:1;flex-direction:column}.-mN9z{flex:1;padding:0 64px;color:#fff;font-family:\"Merriweather\",serif;text-align:center}.RBDBz:active .-mN9z{-webkit-tap-highlight-color:rgba(0,0,0,0);color:hsla(0,0%,100%,.85)}@media(any-hover:hover){.RBDBz:hover .-mN9z{color:hsla(0,0%,100%,.85)}}.qZJfx{margin:16px 0;font-size:64px;font-weight:700;line-height:56px}.MhD5o{margin:24px 0;font-size:26px;font-weight:700;line-height:32px;transition:color .3s ease-out;-webkit-line-clamp:4;max-height:128px}.MhD5o,.MhD5o.rpY\\+Z{-webkit-box-orient:vertical;display:block;display:-webkit-box;overflow:hidden}.MhD5o.rpY\\+Z{margin:16px 0;-webkit-line-clamp:2;max-height:64px}.NRn6F{margin-bottom:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardBackground": "RBDBz",
	"circle": "rOXPB",
	"content": "_7EdYX",
	"path": "ulGT3",
	"body": "-mN9z",
	"number": "qZJfx",
	"title": "MhD5o",
	"underNumber": "rpY+Z",
	"readMore": "NRn6F"
};
module.exports = ___CSS_LOADER_EXPORT___;
