/**
 * Префикс урлов страниц, на которых надо добавлять теги в карточку.
 * Запись /foo вернет истину для любого из урлов:
 * /foo
 * /foo_bar
 * /foo/bar
 */
export const PATHS_PREFIXES_FOR_USING_TAGS = ['/education', '/tegi/education'];
/**
 * Префикс урлов страниц, на которых не надо показывать соответствующие родительские рубрики в карточке.
 * Запись /foo вернет истину для любого из урлов:
 * /foo
 * /foo_bar
 * /foo/bar
 */
export const PATHS_PREFIXES_TO_EXCLUDE_PARENT_RUBRICS = ['/franchises', '/sport'];
