// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NF3Cn{display:flex;width:100%;min-height:44px;align-items:center;justify-content:space-between;padding:10px 16px 2px;grid-gap:8px;gap:8px}@media screen and (min-width:768px){.NF3Cn{min-height:52px;padding:10px 16px}}.NF3Cn.mJ0u\\+,.NF3Cn.mJ0u\\+.OYelS.Jyj-g,.NF3Cn.mJ0u\\+.ff-g0.Jyj-g{color:#767676}.NF3Cn._3v4Qx,.NF3Cn._3v4Qx.OYelS.Jyj-g,.NF3Cn._3v4Qx.ff-g0.Jyj-g{color:#989898}.OYelS.dIeMQ,.ff-g0.dIeMQ{color:#0082ff}.OYelS.yFPQ5,.ff-g0.yFPQ5{color:#ffa00a}.OYelS.KJjPe,.ff-g0.KJjPe{color:#f02d37}.xJpMh{overflow:hidden;width:100%;text-overflow:ellipsis;white-space:nowrap}.xJpMh.vLtP4{text-align:left}.xJpMh.\\+xF\\+m{text-align:center}.kkiV8 .xJpMh.\\+xF\\+m{margin-left:32px}.xJpMh.ZaIZH{text-align:right}.VOuH\\+{font-size:13px;line-height:20px}.mJ0u\\+ .VOuH\\+{color:#767676}._3v4Qx .VOuH\\+{color:#989898}.VOuH\\+:first-letter{text-transform:uppercase}.VOuH\\+:before{margin:0 8px;font-weight:700}.VOuH\\++.VOuH\\+:before{content:\"·\"}.mJ0u\\+ .VOuH\\++.VOuH\\+:before{color:#767676}._3v4Qx .VOuH\\++.VOuH\\+:before{color:#989898}.OYelS.dIeMQ .VOuH\\+{color:#0082ff}.dIeMQ a.VOuH\\+{color:#0082ff;cursor:pointer;text-decoration:none;transition:color .2s}.dIeMQ a.VOuH\\+:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#006ee1}@media(any-hover:hover){.dIeMQ a.VOuH\\+:hover{color:#006ee1}}.yFPQ5 a.VOuH\\+,.OYelS.yFPQ5 .VOuH\\+{color:#ffa00a;transition:opacity .2s}.KJjPe a.VOuH\\+,.OYelS.KJjPe .VOuH\\+{color:#f02d37;transition:opacity .2s}.Jyj-g a.VOuH\\+:active,.yFPQ5 a.VOuH\\+:active,.KJjPe a.VOuH\\+:active{-webkit-tap-highlight-color:rgba(0,0,0,0);opacity:.7}@media(any-hover:hover){.Jyj-g a.VOuH\\+:hover,.yFPQ5 a.VOuH\\+:hover,.KJjPe a.VOuH\\+:hover{opacity:.7}}.mJ0u\\+.Jyj-g a.VOuH\\+,.mJ0u\\+.OYelS.Jyj-g .VOuH\\+{color:#767676;transition:opacity .2s}._3v4Qx.Jyj-g a.VOuH\\+,._3v4Qx.OYelS.Jyj-g .VOuH\\+{color:#989898;transition:opacity .2s}.NF3Cn .Ei9WX{flex:0 0 auto;margin-right:-8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NF3Cn",
	"light": "mJ0u+",
	"isAd": "OYelS",
	"entry-gray": "Jyj-g",
	"isLastEntryWithLink": "ff-g0",
	"dark": "_3v4Qx",
	"entry-blue": "dIeMQ",
	"entry-orange": "yFPQ5",
	"entry-red": "KJjPe",
	"entries": "xJpMh",
	"left": "vLtP4",
	"center": "+xF+m",
	"withIconButton": "kkiV8",
	"right": "ZaIZH",
	"entry": "VOuH+",
	"iconButton": "Ei9WX"
};
module.exports = ___CSS_LOADER_EXPORT___;
