import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { getArticleInfo } from '~/utils';
import { RATINGS_TAG } from '~/constants';
export default defineComponent({
    name: 'CardBackground',
    props: {
        data: {
            required: true,
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Article info.
         */
        const articleInfo = getArticleInfo.call(root, props.data);
        /**
         * First author.
         */
        const [firstAuthor] = articleInfo.authors || [];
        /**
         * Header entries.
         */
        const entries = [articleInfo.publishedAt, ...articleInfo.rubrics, firstAuthor?.title].filter(Boolean);
        const isRating = Boolean(props.data.type === 'rating' ||
            props.data.rating ||
            (props.data.ratings || []).length > 0 ||
            (props.data.tags || []).some(({ id = '' }) => id === RATINGS_TAG.id));
        const title = computed(() => {
            const { title } = props.data;
            if (isRating && !isNaN(parseInt(title.charAt(0)))) {
                const [number, ...text] = title.split(' ');
                return {
                    number: number.trim(),
                    text: text.join(' ').trim(),
                };
            }
            return title;
        });
        return {
            articleInfo,
            css,
            entries,
            isRating,
            title,
        };
    },
});
