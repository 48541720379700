import { computed, defineComponent, ref, useCssModule, useRoute } from '@nuxtjs/composition-api';
import { PATHS_PREFIXES_FOR_USING_TAGS, PATHS_PREFIXES_TO_EXCLUDE_PARENT_RUBRICS } from './';
export default defineComponent({
    name: 'CardHeader',
    props: {
        alignment: {
            default: 'left',
            type: String,
            validator: (value) => {
                return ['center', 'right', 'left'].includes(value);
            },
        },
        article: {
            default: '',
            type: String,
        },
        bookmarkTheme: {
            default: 'dark',
            type: String,
            validator: (value) => {
                return ['a11y', 'light', 'dark'].includes(value);
            },
        },
        entries: {
            required: true,
            type: Array,
        },
        entryTheme: {
            default: 'blue',
            type: String,
            validator: (value) => {
                return ['blue', 'gray', 'orange', 'red'].includes(value);
            },
        },
        isAd: {
            default: false,
            type: Boolean,
        },
        theme: {
            default: 'dark',
            type: String,
            validator: (value) => {
                return ['dark', 'light'].includes(value);
            },
        },
        withBookmark: {
            default: true,
            type: Boolean,
        },
    },
    setup(props) {
        const css = useCssModule();
        const route = useRoute();
        const isExternalArticle = props?.article?.startsWith('http') || props?.article?.startsWith('//');
        const isLastEntryWithLink = ref(false);
        const shouldUseTags = computed(() => {
            let shouldUse = false;
            for (const path of PATHS_PREFIXES_FOR_USING_TAGS) {
                if (route.value.path.startsWith(path)) {
                    shouldUse = true;
                    break;
                }
            }
            return shouldUse;
        });
        const currentEntries = computed(() => {
            const { path } = route.value;
            const entries = shouldUseTags.value ? props.entries : props.entries.filter(({ to }) => !to?.startsWith('/tegi'));
            const excludingEntry = entries.find(({ to }) => PATHS_PREFIXES_TO_EXCLUDE_PARENT_RUBRICS.includes(to));
            if (excludingEntry && path.startsWith(excludingEntry.to)) {
                const rubrics = entries.filter(({ to }) => to?.startsWith(excludingEntry.to));
                const hasSubrubrics = rubrics?.length > 1;
                if (hasSubrubrics) {
                    const excludingIndex = entries.findIndex(({ to }) => to === excludingEntry.to);
                    entries.splice(excludingIndex, 1);
                }
            }
            let hasObject = false;
            const result = [];
            for (let i = 0; i < entries.length; i++) {
                const entry = entries[i];
                isLastEntryWithLink.value = typeof entry === 'object';
                if (isLastEntryWithLink.value) {
                    if (hasObject) {
                        continue;
                    }
                    hasObject = true;
                }
                result.push(entry);
            }
            return result;
        });
        function handleClick(entry) {
            window.dispatchEvent(new CustomEvent('clickCardHeader', {
                detail: entry,
            }));
        }
        return {
            currentEntries,
            css,
            handleClick,
            isExternalArticle,
            isLastEntryWithLink,
            shouldUseTags,
        };
    },
});
